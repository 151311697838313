//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIconButton from 'kolibri.coreVue.components.UiIconButton';

export default {
  name: 'TocButton',
  components: {
    UiIconButton,
  },
  $trs: {
    toggleTocSideBar: 'Toggle table of contents side bar',
  },
};

