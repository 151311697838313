//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiAlert from 'kolibri.coreVue.components.UiAlert';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'LoadingError',
  components: {
    UiAlert,
  },
  mixins: [themeMixin],
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  $trs: {
    couldNotLoadThisBook: 'Could not load this book',
  },
};

