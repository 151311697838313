//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIconButton from 'kolibri.coreVue.components.UiIconButton';

export default {
  name: 'NextButton',
  components: {
    UiIconButton,
  },
  props: {
    // TODO - refactor to use themes properly
    color: {
      type: String,
      required: true,
      validator(val) {
        return ['black', 'white'].includes(val);
      },
    },
    isRtl: {
      type: Boolean,
      required: true,
    },
  },
  $trs: {
    goToNextPage: 'Go to next page',
  },
};

