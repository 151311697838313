//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIconButton from 'kolibri.coreVue.components.UiIconButton';

export default {
  name: 'SettingsButton',
  components: {
    UiIconButton,
  },
  $trs: {
    toggleSettingsSideBar: 'Toggle settings side bar',
  },
};

